<template>
  <!-- 最外层的大盒子 -->
  <div class="bigBox">
    <div class="box" ref="box">
      <!-- 滑动盒子 -->
      <div class="pre-box" ref="preRef">
        <h1>WELCOME</h1>
        <p>JOIN US!</p>
        <div class="img-box">
          <img :src="flag == true ? imgList[0] : imgList[1]" alt="" />
        </div>
      </div>
      <!-- 注册盒子 -->
      <div class="register-form">
        <!-- 标题盒子 -->
        <div class="title-box">
          <h1>注册</h1>
        </div>
        <!-- 输入框盒子 -->
        <el-form
          ref="registerFormRef"
          :model="registerForm"
          :rules="rules"
          label-with="5px"
        >
          <el-form-item prop="username" label=" ">
            <el-input
              type="text"
              placeholder="用户名"
              :suffix-icon="User"
              v-model="registerForm.username"
            />
          </el-form-item>
          <el-form-item prop="password" label=" ">
            <el-input
              type="password"
              placeholder="密码"
              :suffix-icon="Lock"
              v-model="registerForm.password"
            />
          </el-form-item>
          <el-form-item prop="oldPassword" label=" ">
            <el-input
              type="password"
              placeholder="确认密码"
              :suffix-icon="Lock"
              v-model="registerForm.oldPassword"
            />
          </el-form-item>
        </el-form>
        <!-- 按钮盒子 -->
        <div class="btn-box">
          <button @click="enrollForm(registerFormRef)">注册</button>
          <!-- 绑定点击事件 -->
          <p @click="mySwitch">已有账号?去登录</p>
        </div>
      </div>
      <!-- 登录盒子 -->
      <div class="login-form">
        <!-- 标题盒子 -->
        <div class="title-box">
          <h1>登录</h1>
        </div>
        <!-- 输入框盒子 -->
        <el-form
          ref="loginFormRef"
          :model="ruleFrom"
          :rules="rules"
          label-with="5px"
        >
          <el-form-item prop="username" label="">
            <el-input
              type="text"
              placeholder="用户名"
              :suffix-icon="User"
              v-model="ruleFrom.username"
            />
          </el-form-item>
          <el-form-item prop="password" label="">
            <el-input
              type="password"
              placeholder="密码"
              :suffix-icon="Lock"
              v-model="ruleFrom.password"
            />
          </el-form-item>
        </el-form>
        <!-- 按钮盒子 -->
        <div class="btn-box">
          <button @click="loginForm(loginFormRef)">登录</button>
          <!-- 绑定点击事件 -->
          <p @click="mySwitch">没有账号?去注册</p>
        </div>
      </div>
    </div>
    <div class="Record">
      <a href="http://beian.miit.gov.cn">皖ICP备2024035062号</a>
    </div>
  </div>
</template>

<script >
import { reactive, defineComponent, ref, getCurrentInstance } from "vue";
import { doLogin, doEnroll } from "@/apis/user";
import { request } from "@/utils/request";
import { mapMutations } from "vuex";
import Axios from "axios";

export default defineComponent({
  //setup 等同于 vue中的data+created
  setup() {
    //组件的代理:返回的不是本体,是代理人
    const { proxy } = getCurrentInstance();
    //console.log(proxy.$ajax, "自己配置的ajax");
    // console.log(proxy.$route, "vue-router");
    //console.log(proxy.$store, "vuex");

    const preRef = ref("");
    const imgList = ref([
      require("../assets/img/out.jpg"),
      require("../assets/img/time.jpg"),
    ]);
    let flag = ref(true);
    const mySwitch = () => {
      if (flag.value) {
        preRef.value.style.background = "#c9e0ed";
        preRef.value.style.transform = "translateX(100%)";
      } else {
        preRef.value.style.background = "#E8AF81";
        preRef.value.style.transform = "translateX(0%)";
      }
      flag.value = !flag.value;
      // console.log(flag.value);
    };

    //引用页面的组件
    const loginFormRef = ref();
    const registerFormRef = ref();
    //响应式:类似vue中的data
    // 登录
    const ruleFrom = reactive({
      username: "",
      password: "",
    });
    // 注册
    const registerForm = reactive({
      username: "",
      password: "",
      oldPassword: "",
    });
    const rules = reactive({
      username: [
        { required: true, message: "请输入用户名", trigger: "blur" },
        { min: 1, max: 5, message: "长度应该在1~5个字符之间", trigger: "blur" },
      ],
      password: [
        { required: true, message: "请输入密码", trigger: "blur" },
        { min: 1, message: "长度应该大于1", trigger: "blur" },
      ],
      oldPassword: [
        { required: true, message: "请输入密码", trigger: "blur" },
        { min: 1, message: "长度应该大于1", trigger: "blur" },
      ],
    });
    //用户登录
    const loginForm = function (formRef) {
      formRef.validate(async (valid) => {
        if (valid) {
          // console.log("成功");
          const res = await doLogin({
            username: ruleFrom.username,
            password: ruleFrom.password,
          });
          console.log("结果:" + res);
          localStorage.setItem("name", ruleFrom.username);
          // request.interceptors.response.use((response) => {
          //   if (response.data.code == 200) {
          //
          //   }
          // });
          //console.log("请求数据", res);
          // console.log("请求数据", valid);
          this.$router.push("/home");

          // console.log("token:", res.data.data.token);
          // let accessToken = res.data.data.token;
          //localStorage.setItem("Token", accessToken);
          // console.log("获取token:", localStorage.getItem("Token"));
          // data: _this.ruleFrom;
          // _this.useToken.userToken = "Bearer" + res.data.data.token;
          // // console.log(_this.useToken);
          // _this.changeLogin({ Authorization: _this.useToken.userToken });
          // _this.$router.push("/home");
          // alert("登录成功");

          //检查login状态,
          //console.log(chekLogin(), "登录后的信息");

          //登录请求
        } else {
          //console.log("验证失败");
          alert("账号或者密码错误");
          return false; //禁止默认事件
        }
      });
    };
    // 注册用户
    const enrollForm = function (formRef) {
      formRef.validate(async (valid) => {
        if (valid) {
          const res = await doEnroll({
            username: registerForm.username,
            password: registerForm.password,
            oldPassword: registerForm.oldPassword,
          });
          registerForm.username = "";
          registerForm.password = "";
          registerForm.oldPassword = "";
          flag.value = true;
          preRef.value.style.background = "#E8AF81";
          preRef.value.style.transform = "translateX(0%)";
          //this.$router.push("/login");
        } else {
          flag.value = false;
          // console.log(flag.value + "失败");
          // console.log("失败");
          preRef.value.style.background = "#E8AF81";
          preRef.value.style.transform = "translateX(0%)";
          return false;
        }
      });
    };
    return {
      // ...mapMutations(["changeLogin"]),
      loginFormRef,
      registerFormRef,
      ruleFrom,
      registerForm,
      rules,
      loginForm,
      enrollForm,
      mySwitch,
      imgList,
      preRef,
    };
  },
});
</script>

<style scoped>
/* 去除input的轮廓 */
input {
  outline: none;
}
/* 页面备案链接 */
.Record a {
  position: absolute;
  bottom: 0;
  left: 50%;
  text-decoration: none;
  color: azure;
}
.Record a:hover {
  color: #000;
}
.bigBox {
  /* 溢出隐藏 */
  height: 100vh;
  overflow-x: hidden;
  display: flex;
  /* 渐变方向从左到右 */
  background: linear-gradient(to right, rgb(232, 175, 129), rgb(143, 197, 219));
}

/* 最外层的大盒子 */
.box {
  width: 1050px;
  height: 600px;
  display: flex;
  /* 相对定位 */
  position: relative;
  z-index: 2;
  margin: auto;
  /* 设置圆角 */
  border-radius: 8px;
  /* 设置边框 */
  border: 1px solid rgba(255, 255, 255, 0.6);
  /* 设置盒子阴影 */
  box-shadow: 2px 1px 19px rgba(0, 0, 0, 0.1);
}

/* 滑动的盒子 */
.pre-box {
  /* 宽度为大盒子的一半 */
  width: 50%;
  height: 100%;
  /* 绝对定位 */
  position: absolute;
  /* 距离大盒子左侧为0 */
  left: 0;
  /* 距离大盒子顶部为0 */
  top: 0;
  z-index: 99;
  border-radius: 4px;
  background-color: #e8af81;
  box-shadow: 2px 1px 19px rgba(0, 0, 0, 0.1);
  /* 动画过渡，先加速再减速 */
  transition: 0.5s ease-in-out;
}

/* 滑动盒子的标题 */
.pre-box h1 {
  margin-top: 150px;
  text-align: center;
  /* 文字间距 */
  letter-spacing: 5px;
  color: white;
  /* 禁止选中 */
  user-select: none;
  /* 文字阴影 */
  text-shadow: 4px 4px 3px rgba(0, 0, 0, 0.1);
}

/* 滑动盒子的文字 */
.pre-box p {
  height: 30px;
  line-height: 30px;
  text-align: center;
  margin: 20px 0;
  /* 禁止选中 */
  user-select: none;
  font-weight: bold;
  color: white;
  text-shadow: 4px 4px 3px rgba(0, 0, 0, 0.1);
}

/* 图片盒子 */
.img-box {
  width: 200px;
  height: 200px;
  margin: 20px auto;
  /* 设置为圆形 */
  border-radius: 50%;
  /* 设置用户禁止选中 */
  user-select: none;
  overflow: hidden;
  box-shadow: 4px 4px 3px rgba(0, 0, 0, 0.1);
}

/* 图片 */
.img-box img {
  width: 100%;
  transition: 0.5s;
}

/* 登录和注册盒子 */
.login-form,
.register-form {
  flex: 1;
  height: 100%;
}

/* 标题盒子 */
.title-box {
  height: 300px;
  line-height: 500px;
}

/* 标题 */
.title-box h1 {
  text-align: center;
  color: white;
  /* 禁止选中 */
  user-select: none;
  letter-spacing: 5px;
  text-shadow: 4px 4px 3px rgba(0, 0, 0, 0.1);
}

/* 输入框盒子 */
.el-form {
  display: flex;
  /* 纵向布局 */
  flex-direction: column;
  /* 水平居中 */
  align-items: center;
}
.el-form-item {
  width: 65%;
}
/* 输入框 */
input {
  /* width: 60%; */
  height: 40px;
  margin-bottom: 20px;
  text-indent: 10px;
  border: 1px solid #fff;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 120px;
  /* 增加磨砂质感 */
  backdrop-filter: blur(10px);
}

input:focus {
  /* 光标颜色 */
  color: #b0cfe9;
}

/* 聚焦时隐藏文字 */
input:focus::placeholder {
  opacity: 0;
}

/* 按钮盒子 */
.btn-box {
  display: flex;
  justify-content: center;
}

/* 按钮 */
button {
  width: 100px;
  height: 30px;
  margin: 0 7px;
  line-height: 30px;
  border: none;
  border-radius: 4px;
  background: linear-gradient(to right, rgb(232, 175, 129), rgb(143, 197, 219));
  color: white;
}

/* 按钮悬停时 */
button:hover {
  /* 鼠标小手 */
  cursor: pointer;
  /* 透明度 */
  opacity: 0.8;
}

/* 按钮文字 */
.btn-box p {
  height: 30px;
  line-height: 30px;
  /* 禁止选中 */
  user-select: none;
  font-size: 14px;
  color: white;
}

.btn-box p:hover {
  cursor: pointer;
  border-bottom: 1px solid white;
}
</style>