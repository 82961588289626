// import router from '@/router';
import Axios from 'axios';
//import {getToken} from '@/utils/index.js';

export const request = Axios.create({
    baseURL:'/api'
});


export const textai = "";


request.interceptors.response.use(response => {
    //console.log('响应回来',response.data.code);
    //console.log("请求",response.data.data);
    //console.log("请求",response.data.data.token);
    if(response.data.code == 404){
       alert("用户名已存在");
    }else if(response.data.code == 401){
        alert('请从新登录')
    }else if(response.data.code == 405){
        alert('请求太频繁,请稍后再试')
    }else if(response.data.code == 406){
        alert('账户或者密码错误');
    }else if(response.data.code == 9999){
        alert('未知异常请联系管理员')
    }else if(response.data.code == 201){
        let accessToken = response.data.data.token;
        localStorage.setItem("Token", accessToken);
    }else if(response.status== 500){
        alert('服务器异常');
    }else if(response.status== 402){
        alert('时间要在一个月以后')
    }else if(response.data.code == 199){
        let mess = response.data.data;
        localStorage.setItem("message",mess)
    }
},error => {
    return Promise.reject(error);
}
)


//拦截器中获取的token
request.interceptors.request.use((config)=>{
    if(config.push === '/'){

    }else{
        if(localStorage.getItem('Token')){
            config.headers.Authorization='Bearer '+localStorage.getItem('Token');
        }
    }
    // let token;
    // if(token = getToken()){
    //     //挂载请求头
    //     config.headers.token = token;
    // }
    return config;
},err=>{
    return Promise.reject(err);
});

//响应式拦截器
request.interceptors.response.use((response)=>{
    //响应式获取token,保存到本地存储
    // if(response?.data?.token){
    //     window.sessionStorage.setItem('token',response?.data?.token)
    // }
    return response;
},err=>{
    //200状态码以上的错误处理,API错误日志收集
    return Promise.reject(err);
})