// import { from } from 'core-js/core/array';
import { createRouter, createWebHistory } from 'vue-router';
import login from '../views/login.vue';
import { createStore } from 'vuex';



const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: login
  },
  {
    path: '/home',
    name: 'home',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/home.vue')
  },
  {
    path: '/past',
    name: 'past',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/past.vue')
  },
  {
    path: '/chat',
    name: 'chat',
    component: () => import('../views/chat.vue')
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


//导航守卫
//使用router.beforeEach 注册一个全局前置守卫,判断用户是否登录
// router.beforeEach((to,from,next) => {
//   if(to.path === '/'){
//     next();
//   }else {
//     let token = localStorage.getItem('Token');
//     if(token == null || token === ''){
//       console.log('请先登录');
//       next();
//     }else {
//       next();
//     }
//   }
// })
//一天过期token
let EXPIRESTIME = 86400000;
router.beforeEach((to,from,next) => {
  let token = localStorage.getItem('Token');
//  console.log('结果'+localStorage.getItem('Token'));
  if(token || to.path === '/login'){
    next();
  }else{
    next('/login')
  }
  if(token){
    let date = new Date().getTime();
    //大于就是过期,小于就是没有过期
    if(date - token.startTime > EXPIRESTIME){
      localStorage.removeItem('Token');
      localStorage.removeItem('name');
      next('/login')
    }else{
      next();
    }
  }
})



export default router;
