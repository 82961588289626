import { request } from "@/utils/request";

//一个个 请求方式 + 参数简单处理 + 方法
export const doLogin  = ({username,password})=>{
    return request.post('/admin/public/user/login',{
        username,password
    },{})
}

export const doEnroll  = ({username,password,oldPassword})=>{
    return request.post('/admin/public/user/insert',{
        username,password,oldPassword
    },{})
}

export const doFuture = ({growTheme,growContent,growNewTime,growMail,readName})=>{
    return request.post('/admin/grow/growInsert',{
        growTheme,growContent,growNewTime,growMail,readName
    })
}

export const doPast = ({pastTheme,pastContent})=>{
    return request.post('/admin/past/private/pastInset',{
        pastTheme,pastContent
    })
}

export const ai = ({message})=>{
    return request.post('/chat/GPT',{
        message
    })
}
