import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import Axios from 'axios'
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
// 英文改中文
import zhCn from 'element-plus/es/locale/lang/zh-cn'



//配置请求拦截器
Axios.defaults.baseURL= '/api' //需要配置代理

let app = createApp(App);
//全局配置：让组件实例 this.$ajax
app.config.globalProperties.$ajax = Axios;//vue2: vue.prototype.$ajax = Axios
app.use(router).use(ElementPlus,{locale:zhCn}).use(VueQuillEditor).mount('#app')
